import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import StylePage from '@/views/StylePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.location.href = "https://clothbox.kr/"
    }
  },
  {
    path: '*',
    component: Home
  },

  {
    path: '/s/:hashcode',
    component: StylePage,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  const title = to.meta.title === undefined ? 'CLOTHBOX | 내 옷을 더 잘 활용하는 방법' : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router
