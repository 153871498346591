export const defaultMeta = [
  {
    vmid: 'description',
    name: 'description',
    content:
      '클로즈박스를 통해 내 옷과 스타일을 언제 어디서나 확인해보세요',
  },
  {
    vmid: 'keywords',
    name: 'keywords',
    content: '옷장,옷,스타일,클로즈박스',
  },
  {
    vmid: 'author',
    name: 'author',
    content: 'lappce',
  },
  {
    vmid: 'og:image',
    name: 'og:image',
    content: 'https://www.clothbox.app/img/banner.2c79f316.png',
  },
  {
    vmid: 'og:title',
    name: 'og:title',
    content: 'CLOTHBOX | 내 옷을 더 잘 활용하는 방법',
  },
  {
    vmid: 'title',
    name: 'title',
    content: 'CLOTHBOX | 내 옷을 더 잘 활용하는 방법',
  },
];
