<template>
  <v-app>
    <v-main class="grey lighten-3">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {defaultMeta} from "@/utils/meta";

export default {
  name: 'App',
  components: {},
  data: () => ({}),
  metaInfo: {
    meta: defaultMeta,
  },
};
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&display=swap');

$font-family: 'IBM Plex Sans KR';
.v-application {
  font-family: $font-family, sans-serif !important;

  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
}
</style>