<template>
  <v-container id="app-container">
    <v-row>
      <v-col
          class="ma-0 pa-0"
          cols="12"
          sm="12"
      >
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="px-8 px-md-16"
        >
          <v-img
              :aspect-ratio="3/4"
              :src="styleImage"
          ></v-img>
          <div class="font-weight-black custom-text-title">{{ userNickname.slice(0, 20) }} 's</div>
          <div class="font-weight-black mb-4 custom-text-title">{{ occasions }} 스타일</div>
          <div class="text-body-1 pr-12 word-break-keep-all">
            {{ description }}
          </div>
          <div class="thick-divider mt-4"></div>
          <v-list two-line>
            <template v-for="(item, index) in items">
              <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  class="pl-0"
              >
                {{ item.header }}
              </v-subheader>
              <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  class="pl-0"
                  style="height: 0.2rem"
              ></v-divider>
              <v-list-item
                  v-else
                  :key="item.title"
                  class="pl-0"
              >
                <v-list-item-avatar tile size="100">
                  <img :src="item.image" style="object-fit: contain">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="text-body-1 font-weight-bold">
                      {{item.category}}
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="text-body-2 font-weight-light only-two-line">
                      {{ item.description }}
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <div class="text-body-2 font-weight-medium only-two-line">
                      {{ item.tag }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-sheet>
        <v-sheet class="mt-12 promote-store" color="#215AD3">
          <v-row>
            <v-col justify="center" align="center">
              <img src="@/assets/banner.png"
                   style="border-radius: 13px; width: 280px;"
              />

              <div v-if="os==='android'">
                <a href='https://play.google.com/store/apps/details?id=com.lappce.clothbox&utm_source=styleshare&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                   style="display: inline-block; overflow: hidden; border-radius: 13px;">
                  <img alt='다운로드하기 Google Play'
                       src='https://play.google.com/intl/ko/badges/static/images/badges/ko_badge_web_generic.png'
                       style="border-radius: 13px; width: 200px;"
                  />
                </a>
              </div>
              <div v-if="os==='ios'">
                <a href="https://apps.apple.com/kr/app/%ED%81%B4%EB%A1%9C%EC%A6%88%EB%B0%95%EC%8A%A4-clothbox/id1579997182?itsct=apps_box_badge&amp;itscg=30200"
                   style="display: inline-block; overflow: hidden; border-radius: 13px; width: 170px; height: 83px;">
                  <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ko-kr?size=250x83&amp;releaseDate=1629849600&h=5731ad2d4d9e5166fa28056279357124"
                      alt="Download on the App Store" style="border-radius: 13px; width: 180px; height: 80px;">
                </a>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  function getImageUrl(imageUrl) {
    if (process.env['NODE_ENV'] == "production") {
      return imageUrl;
    }
    return process.env.VUE_APP_ENV_API_URL + imageUrl;
  }

  export default {
    metaInfo() {
      return {
        title: this.userNickname,
        titleTemplate: '%s | CLOTHBOX',
        meta: [
          {
            vmid: 'og:title',
            property: 'og:title',
            content: this.userNickname + '의 스타일',
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: this.styleImage,
          },
        ]
      }
    },
    mounted() {
      Vue.axios.get('/api/share/style/get/?hashcode=' + this.$route.params.hashcode,)
        .then((response) => {
          // console.log(response)
          let data = response.data
          this.styleImage = getImageUrl(data.image)
          this.userNickname = data.user_nickname
          this.description = data.description
          this.occasions = data.occasions_label
          let items = []
          for (let item of data.items) {
            let cloth = item.cloth
            let tagsText = Vue._.map(cloth.tags, function (tag) {
              return "#" + tag
            }).join(" ")

            items.push(
              {
                image: getImageUrl(cloth.main_image.image),
                category: cloth.category_label,
                description: cloth.description,
                tag: tagsText,
              }
            )
            items.push(
              {divider: true,},
            )
          }
          this.items = items
          return response
        })
        .catch((error) => {
          return error
        })

      this.checkMobile()
    },
    data: () => ({
      styleImage: '',
      userNickname: '',
      occasions: [],
      description: '',
      items: [],
      os: '',
    }),
    methods: {
      checkMobile() {
        const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.indexOf('android') > -1) {
          this.os = "android";
        } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
          this.os = "ios";
        } else {
          this.os = "other";
        }

      }
    }

  }
</script>

<style>
  #app-container {
    height: inherit;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
  }

  .custom-text-title {
    font-size: 1.75em !important;
    line-height: 1.2;
  }

  .word-break-keep-all {
    word-break: break-word;
  }

  .thick-divider {
    width: 4rem;
    height: 0.45rem;
    background-color: rgba(0, 0, 0, 1);
  }

  .only-two-line {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .promote-store {

  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
</style>
