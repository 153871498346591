<template>
  <v-container id="app-container">

  </v-container>
</template>

<script>
  export default {
    data: () => ({}),
  }
</script>

<style>

</style>
